<template>
    <el-dialog
        class="recommend-dialog-wrapper"
        width="818px"
        :show-close="true"
        :lock-scroll="false"
        :visible.sync="isShow"
        :title="recommendTitle"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @close="closeDialog"
    >
        <div class="recommend-header">
            <div class="recommend-header-left search-area-contianer">
                <div
                    class="search-input"
                    :class="{'less-width-input': authorizationFirms.length}">
                    <div
                        class="search-city-btn"
                        :title="selectedCity.text"
                        v-if="type == 1">
                        <el-popover
                            placement="bottom-start"
                            width="629"
                            :visible-arrow="provinceCityConfig.arrowIsShow"
                            v-model="provinceCityConfig.provinceCityIsShow"
                            trigger="click"
                            popper-class="tooltip-province-city"
                            :offset="-86">
                            <span slot="reference" class="search-city-content">
                                <i>{{ selectedCity.text }}</i>
                                <span class="el-icon-arrow-down"></span>
                            </span>
                            <province-city
                                ref="provinceCity"
                                :grid-length="7"
                                :enableHeader="provinceCityConfig.enableHeader"
                                :enableFooter="provinceCityConfig.enableFooter"
                                @confirm="handleCitySelectConfirm"
                                @cancel="handleCitySelectCancel"
                            ></province-city>
                        </el-popover>
                    </div>
                    <div class="search-keywords-input" :class="type == 1 ? 'with-city' : ''">
                        <el-input
                            slot="reference"
                            v-model="searchText"
                            placeholder="输入关键字，多个关键字用空格隔开"
                            @keydown.enter.native="getList(false, false)"
                            maxlength="100"
                        >
                        </el-input>
                        <!-- <input
                            slot="reference"
                            maxlength="100"
                            type="text"
                            v-model="searchText"
                            @keyup.enter="getList(false, false)"
                            placeholder="输入关键字，多个关键字用空格隔开"
                        /> -->
                    </div>
                    <div class="search-btn" @click="getList(false, false)">
                        <span>搜索</span>
                    </div>
                </div>
            </div>
            <div class="recommend-header-right">
                <div v-if="type == 0">
                    <el-dropdown
                        trigger="click"
                        placement="bottom-end"
                        class="recommend-select-dropdown"
                        :class="{'whithout-source-select': firmId && firmId !== userInfo.firmId}"
                        @command="firmIdSelect"
                        @visible-change="firmIdSelectShow = !firmIdSelectShow"
                        v-if="authorizationFirms.length">
                        <span
                            class="el-dropdown-link text-grey"
                            :title="selectedFirmName"
                            :class="firmIdSelectShow ? 'actived' : ''">
                            {{selectedFirmName}}
                            <i :class="firmIdSelectShow ? 'el-icon-arrow-up' :'el-icon-arrow-down'"></i>
                        </span>
                        <el-dropdown-menu class="recommend-body-popover" slot="dropdown">
                            <el-dropdown-item
                                :command="firm.firmId"
                                v-for="(firm, index) in authorizationFirms"
                                :key="index"
                                :class="(!firmId && firm.firmId == userInfo.firmId) || firmId == firm.firmId ? 'el-dropdown-item-selected' : ''">
                                {{firm.shortName}}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown
                        trigger="click"
                        placement="bottom-end"
                        class="recommend-select-dropdown"
                        @command="jobSourceSelect"
                        @visible-change="jobSourceSelectShow = !jobSourceSelectShow"
                        v-if="!firmId || firmId === userInfo.firmId">
                        <span
                            class="el-dropdown-link text-grey"
                            :class="jobSourceSelectShow ? 'actived' : ''">
                            {{jobSourceSelection[jobSourceIndex].text}}
                            <i :class="jobSourceSelectShow ? 'el-icon-arrow-up' :'el-icon-arrow-down'"></i>
                        </span>
                        <el-dropdown-menu class="recommend-body-popover" slot="dropdown">
                            <el-dropdown-item
                                :command="index"
                                v-for="(jobSource,index) in jobSourceSelection"
                                :key="jobSource.value"
                                :class="jobSourceIndex == index ? 'el-dropdown-item-selected' : ''">
                                {{jobSource.text}}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown
                        trigger="click"
                        placement="bottom-end"
                        class="recommend-select-dropdown"
                        @command="orderBySelect"
                        @visible-change="orderBySelectShow = !orderBySelectShow">
                        <span
                            class="el-dropdown-link text-grey"
                            :class="orderBySelectShow ? 'actived' : ''">
                            {{orderBySelection[orderByIndex].text}}
                            <i :class="orderBySelectShow ? 'el-icon-arrow-up' :'el-icon-arrow-down'"></i>
                        </span>
                        <el-dropdown-menu class="recommend-body-popover" slot="dropdown">
                            <el-dropdown-item
                                :command="orderBy.value"
                                v-for="orderBy in orderBySelection"
                                :key="orderBy.value"
                                :class="orderByIndex == orderBy.value ? 'el-dropdown-item-selected' : ''">
                                {{orderBy.text}}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <!-- <a class="recommend-new-talent-link" href="/Headhunting/MyCompany.html?type=1#/resumeUpload/singleUpload" target="_blank" v-if="type == 1">
                    <el-button>
                        推荐新的候选人
                    </el-button>
                </a> -->
                <a class="recommend-new-talent-link" href="/?type=1#/resumeUpload/singleUpload" target="_blank" v-if="type == 1">
                    <!-- <el-button>
                        推荐新的候选人
                    </el-button> -->
                    <button class="recommend-new-candidate-btn">推荐新的候选人</button>
                </a>
            </div>
        </div>
        <div class="recommend-body" v-loading="loading">
            <el-table
                :height="400"
                :data="tableData"
                :show-header="false"
                tooltip-effect="light"
                v-if="type == 0">
                <el-table-column
                    prop="name"
                    label="职位"
                    width="200"
                    :resizable="false"
                >
                    <template slot-scope="scope">
                        <div class="name-wrapper">
                            <el-tooltip
                                effect="light"
                                :content="scope.row.name"
                                placement="top"
                                :open-delay="300"
                                :disabled="tooltipDisabled"
                                popper-class="recommend-dialog-tooltip"
                                @mouseenter.native ="handleShowTooltip($event)"
                            >
                                <span
                                    class="job-detail-link detail-link"
                                    @click.prevent="handleJumpDetail(scope.row, 'job')"
                                >
                                    {{scope.row.name}}
                                </span>
                            </el-tooltip>
                            <font-icon class="name-wrapper-icon tip-margin" href="#icon-orders_lable_hard" v-if="scope.row.difficultDegree==1"></font-icon>
                            <font-icon class="name-wrapper-icon tip-margin" href="#icon-orders_lable_urgent" v-if="scope.row.emergencyDegree==1"></font-icon>
                            <el-tooltip
                                effect="light"
                                :content="scope.row.expectedRank"
                                placement="top"
                                :open-delay="300"
                                v-if="scope.row.expectedRank"
                                :disabled="tooltipDisabled"
                                popper-class="recommend-dialog-tooltip"
                                @mouseenter.native ="handleShowTooltip($event)"
                            >
                                <span class="expected-rank">{{scope.row.expectedRank}}</span>
                            </el-tooltip>
                            <template v-if="scope.row.activityTags.length > 0">
                                <span
                                    class="activity-tag"
                                    :class="activityTag.id == 'NewJob' ? 'new-job-tag' : ''"
                                    v-for="activityTag in scope.row.activityTags"
                                    :key="activityTag.id"
                                    v-show="activityTag.id !== 'JobEmergency' && activityTag.id !== 'JobDifficultDegree'"
                                >
                                    {{activityTag.name}}
                                </span>
                            </template>
                            <span
                                v-if="scope.row.jobType == 3"
                                class="activity-tag">
                                HR
                            </span>
                            <span
                                v-if="scope.row.jobType == 5"
                                class="activity-tag">
                                代运营
                            </span>
                        </div>
                   </template>
                </el-table-column>
                <el-table-column
                    prop="location"
                    label="城市"
                    width="130"
                    :resizable="false"
                >
                    <template slot-scope="scope">
                        <el-tooltip
                            effect="light"
                            :content="scope.row.location"
                            placement="top"
                            :open-delay="300"
                            :disabled="tooltipDisabled"
                            popper-class="recommend-dialog-tooltip"
                            @mouseenter.native ="handleShowTooltip($event)"
                        >
                            <span class="location">{{scope.row.location}}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="customerName"
                    label="客户"
                    width="154"
                    :resizable="false"
                    :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column
                    prop="created"
                    label="发布日期"
                    width="140"
                    :resizable="false"
                >
                    <template slot-scope="scope">
                        {{scope.row.created}} 发布
                    </template>
                </el-table-column>
                <el-table-column
                    prop="created"
                    label="头像"
                    width="40"
                    :resizable="false"
                >
                    <template slot-scope="scope">
                        <!-- <avatar
                            class="user-avatar"
                            size="sm"
                            :isPosterHR="scope.row.jobType==3"
                            :src="scope.row.avatarUrl"
                            :userId="scope.row.ownerId"
                            :enableCard="true"
                            :enableLink="false"
                        ></avatar> -->
                        <avatar
                            class="user-avatar"
                            size="sm"
                            :src="scope.row.avatarUrl"
                            :userId="scope.row.ownerId"
                            :enableCard="true"
                            :enableLink="false"
                            :currentJobType="scope.row.jobType"
                        ></avatar>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="28"
                    align="center"
                    :resizable="false"
                >
                    <template slot-scope="scope">
                        <span class="recommend-btn" @click="recommend(scope.row, 'job')">推荐</span>
                    </template>
                </el-table-column>
                <template slot="empty">
                    <!-- <div>
                        <span class="empty-img"></span>
                        <p class="empty-data">暂无数据</p>
                    </div> -->
                    <div class="page-empty">
                        <img src="~@src/assets/images/jobList/empty-bg@2x.png" />
                        <p class="des">暂无数据</p>
                    </div>
                </template>
            </el-table>
            <el-table
                :height="400"
                :data="tableData"
                :show-header="false"
                tooltip-effect="light"
                v-if="type == 1"
            >
                <el-table-column
                    prop="realName"
                    label="姓名"
                    width="62"
                    :resizable="false"
                    :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <span class="detail-link" @click="handleJumpDetail(scope.row, 'candidate')">
                            {{scope.row.realName}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="location"
                    label="城市"
                    width="60"
                    :resizable="false"
                    :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column
                    prop="title"
                    label="职位"
                    width="310"
                    :resizable="false"
                    :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column
                    prop="degreeInfo"
                    label="信息"
                    width="262"
                    :resizable="false"
                    :show-overflow-tooltip="true"
                ></el-table-column>
                <el-table-column
                    label="操作"
                    min-width="28"
                    :resizable="false"
                >
                    <template slot-scope="scope">
                        <span class="recommend-btn" @click="recommend(scope.row, 'candidate')">推荐</span>
                    </template>
                </el-table-column>
                <template slot="empty">
                    <!-- <div>
                        <span class="empty-img"></span>
                        <p class="empty-data">暂无数据</p>
                    </div> -->
                    <div class="page-empty">
                        <img src="~@src/assets/images/jobList/empty-bg@2x.png" />
                        <p class="des">暂无数据</p>
                    </div>
                </template>
            </el-table>
        </div>
        <el-pagination
            v-if="tableData && tableData.length > 0"
            class="pagination-common-new"
            :current-page="page.current"
            :page-sizes="[10, 20, 50]"
            :page-size="page.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
        <!-- <el-pagination
            class="pagination-common"
            :current-page="page.current"
            layout="total, prev, pager, next, slot"
            :total="page.total"
            @current-change="handleCurrentChange">
            <span class="pagination-text">
                <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                <span @click="handlePagerJump">跳转</span>
            </span>
        </el-pagination> -->
    </el-dialog>
</template>

<script>
    import rescommendService from '#/js/service/jobService.js';
    import ProvinceCity from '#/component/common/province-city.vue';
    import Avatar from '#/component/common/avatar.vue';

    export default {
        name: 'recommend-dialog',
        components: {
            ProvinceCity,
            Avatar
        },
        props: {
            /*
            * 推荐弹窗选择候选人/职位
            * @param 0: 选择职位 1：选择候选人
            */
            type: 0
        },
        data() {
            return {
                curRecommendJobType: '', // 当前推荐的职位类型，3为HR直招职位
                provinceCityConfig: {
                    enableHeader: true,
                    enableFooter: true,
                    provinceCityIsShow: false,
                    arrowIsShow: false,
                },
                selectedCity: {
                    text: '全国',
                    id: '',
                    isSelect: true,
                },
                searchText: "",
                isShow: false,
                isFirm: false,
                // jobSourceIndex: 1,
                orderByIndex: 0,
                pagerJump: 0,
                tableData: [],
                page: {
                    current: 1,
                    size: 10,
                    total: 0
                },
                loading: false,
                jobSourceIndex: 0,
                jobSourceSelectShow: false,
                jobSourceSelection:[
                    {text:"内部职位",title:"内部职位",value:1},
                    {text:"抢单A2A",title:"抢单A2A",value:3},
                    {text:"抢单HR直招",title:"抢单HR直招",value:4},
                ],
                orderByIndex: 0,
                orderBySelectShow: false,
                orderBySelection:[
                    {text:"更新时间",title:"更新时间",value:0},
                    {text:"发布时间",title:"发布时间",value:1},
                    {text:"佣金金额",title:"佣金金额",value:2},
                    {text:"佣金比例",title:"佣金比例",value:3},
                ],
                tooltipDisabled: true,
                recommendTitle: "",
                firmId: "",
                authorizationFirms: [],
                firmIdSelectShow: false,
                marketJobType: '',
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.user.userInfo;
            },
            isAuthorization() {
                return this.firmId && this.firmId !== this.userInfo.firmId;
            },
            selectedFirmName() {
                let selectedFirm = this.authorizationFirms.find(firm => firm.firmId === this.firmId);
                return selectedFirm && selectedFirm.shortName ? selectedFirm.shortName : this.userInfo.firmShortName
            }
        },
        methods: {
            show(id, jobName = '',type, marketJobType) {
                console.log(type)
                this.curRecommendJobType = type;
                this.marketJobType = marketJobType;
                this.id = id;
                this.isShow = true;
                if(this.type == 0) {
                    this.recommendTitle = '推荐职位';
                    this.isFirm = this.$route.params.isFirm !== undefined ? this.$route.params.isFirm : true;
                    this.fromPage = this.$route.meta.pageCode ? this.$route.meta.pageCode : '';
                    this.getAuthorizationFirms();
                    this.getList();
                } else {
                    this.recommendTitle = '推荐候选人—' + jobName;
                    this.getList(false, true)
                }
            },
            getList(slient = false, isFirst = false) {
                this.loading = true;
                if(!slient) {
                    this.page.current = 1;
                }
                if(this.type == 0) {
                    let params = {
                        orderBy: this.orderBySelection[this.orderByIndex].value,
                        jobSource: this.jobSourceSelection[this.jobSourceIndex].value,
                        keyword: this.searchText,
                        start: (this.page.current - 1) * this.page.size,
                        take: this.page.size
                    };
                    if(this.isAuthorization) {
                        params.firmId = this.firmId;
                        params.jobSource = 1;
                        params.jobStatus = 1;
                    }
                    rescommendService.getJobsForRecommendation(this.id, params)
                    .then((res) => {
                        this.loading = false;
                        if(res) {
                            this.tableData = res.list;
                            if(!slient) {
                                this.page.total = res.total;
                            }
                        }
                    }).catch((err) => {
                        this.loading = false;
                        console.log(err);
                    });
                } else {
                    rescommendService.getCandidatesForRecommendation({
                        JobId: this.id,
                        Location: this.selectedCity.id ? this.selectedCity.id : "",
                        Keywords: this.searchText,
                        Sort: isFirst ? 1 : 0,
                        start: (this.page.current - 1) * this.page.size,
                        take: this.page.size
                    }).then((res) => {
                        this.loading = false;
                        if(res) {
                            this.tableData = res.list;
                            if(!slient) {
                                this.page.total = res.total;
                            }
                        }
                    }).catch((err) => {
                        this.loading = false;
                        console.log(err);
                    });
                }
            },
            jobSourceSelect(value) {
                this.jobSourceIndex = value;
                this.getList();
            },
            orderBySelect(value) {
                this.orderByIndex = value;
                this.getList();
            },
            recommend(data, type){
                // console.log(data)
                if(type == 'job') {
                    window.open(`/Headhunting/MyCompany.html#/recommendDetail?jobId=${data.id}&candidateId=${this.id}&jobType=${data.jobType}&fromPage=${this.fromPage}&isFirm=${this.isFirm.toString()}&curRecommendJobType=${data.jobType}&marketJobType=${data.jobType}`);
                    // window.open(`/#/recommendDetail?jobId=${data.id}&candidateId=${this.id}&jobType=${data.jobType}&fromPage=${this.fromPage}&isFirm=${this.isFirm.toString()}&curRecommendJobType=${data.jobType}&marketJobType=${data.jobType}`);
                    // window.open(`/Headhunting/Job/${data.id}/Recommend?candidateId=${this.id}&jobType=${data.jobType}&fromPage=${this.fromPage}&isFirm=${this.isFirm.toString()}`);
                } else {
                    window.open(`/Headhunting/MyCompany.html#/recommendDetail?jobId=${this.id}&candidateId=${data.candidateId}&isEdit=False&jobType=2&curRecommendJobType=${this.curRecommendJobType}&marketJobType=${this.marketJobType}`);
                    // window.open(`/#/recommendDetail?jobId=${this.id}&candidateId=${data.candidateId}&isEdit=False&jobType=2&curRecommendJobType=${this.curRecommendJobType}&marketJobType=${this.marketJobType}`);
                    // window.open(`/Headhunting/Job/${this.id}/Recommend?candidateId=${data.candidateId}&isEdit=False&jobType=2`);
                }
                this.isShow = false;
            },
            handleCitySelectConfirm() {
                if (this.$refs.provinceCity.selectedCity[0]) {
                    this.selectedCity = this.$refs.provinceCity.selectedCity[0];
                } else {
                    this.selectedCity = {
                        text: '全国',
                        id: '',
                        isSelect: true,
                    };
                }
                this.getList();
                this.$refs.provinceCity.currentIndex = undefined;
                this.$refs.provinceCity.selectedProvince = '';
                return this.provinceCityConfig.provinceCityIsShow = false;
            },
            handleCitySelectCancel() {
                if (this.$refs.provinceCity.selectedCity[0] && this.$refs.provinceCity.selectedCity[0].id !== this.selectedCity.id) {
                    this.$refs.provinceCity.selectCity(this.selectedCity);
                }
                this.$refs.provinceCity.currentIndex = undefined;
                this.$refs.provinceCity.selectedProvince = '';
                return this.provinceCityConfig.provinceCityIsShow = false;
            },
            handleCurrentChange(val) {
                this.page.current = val;
                this.getList(true);
            },
            handleSizeChange(val){
                this.page.size = val;
                this.page.current = 1;
                this.$nextTick(() => {
                    this.getList(true);
                })
            },
            handlePagerJump(){
                let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.page.total/this.page.size);
                if(currentPager > 0 && currentPager <= currentPageTotal){
                    this.handleCurrentChange(currentPager)
                }
            },
            handleShowTooltip(event){
                let cell = event.target;
                if(cell.classList.contains('location')) {
                    this.tooltipDisabled = 154 > cell.offsetWidth;
                } else {
                    this.tooltipDisabled = cell.clientWidth == cell.scrollWidth;
                }
            },
            handleJumpDetail(item, type) {
                switch(type) {
                    case 'job':
                        window.open(`/Headhunting/MyCompany.html#/Job/${item.id}`, '_blank');
                        // window.open(`/#/Job/${item.id}`, '_blank');
                        break;
                    case 'candidate':
                        window.open(`/Headhunting/MyCompany.html#/candidateDetail/${item.candidateId}`, '_blank');
                        // window.open(`/#/candidateDetail/${item.candidateId}`, '_blank');
                        break;
                }
            },
            closeDialog() {
                this.isShow = false;
                this.selectedCity = {
                    text: '全国',
                    id: '',
                    isSelect: true,
                };
                this.searchText = "";
            },
            getAuthorizationFirms() {
                _request({
                    url: "/JobAuthorizationFirms",
                    method: "GET"
                }).then(res => {
                    if(res && res.length) {
                        res.forEach(item => {
                            item.shortName += '(授权)';
                        })
                        res.unshift({
                            firmId: this.userInfo.firmId,
                            shortName: this.userInfo.firmShortName
                        });
                        this.authorizationFirms = res;
                    } else {
                        this.authorizationFirms = [];
                    }
                })
            },
            firmIdSelect(firmId) {
                this.firmId = firmId;
                this.getList();
            }
        }
    }
</script>

<style lang="scss" scope>
.tooltip-province-city {
    max-height: 640px;
    overflow-y: auto;
}
.recommend-dialog-wrapper {
    .el-dialog__title {
        // padding-right: 40px;
        display: inline-block;
        width: 690px;
        line-height: 52px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .el-dialog__headerbtn {
        width: 20px;
        height: 20px;
        border-radius: 12px;
        // background: #f5f5f5;
        font-size: 14px;
        .el-dialog__close {
            color: #999;
            font-weight: bold;
        }
    }
    .recommend-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-left {

            &.search-area-contianer {
                position: relative;
                display: inline-block;
                vertical-align: top;

                height: 38px;
                background: #38BC9D;
                border-radius: 6px;
                border: 2px solid #38BC9D;
                .search-input {
                    display: flex;
                    flex-direction: row;
                    width: 530px;
                    height: 34px;
                    background-color: $primary;
                    // border: 1px solid #DDDDDD;
                    border-radius: 6px;
                    .search-city-btn {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 110px;
                        height: 34px;
                        line-height: 34px;
                        border-radius: 0;
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                        z-index: 5;

                        > span {
                            display: inline-block;
                        }

                        &::after {
                            display: none;
                        }

                        .el-icon-arrow-down:before {
                            content: '\e790';
                            color: #999;
                        }

                        .search-city-content {
                            display: flex;
                            align-items: center;
                            padding-right: 16px;

                            i {
                                display: inline-block;
                                width: 102px;
                                padding: 0 16px;
                                font-size: 14px;
                                color: #999;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }
                    .search-keywords-input {
                        flex-grow: 1;
                        vertical-align: top;

                        &.with-city input {
                            padding-left: 130px;
                        }
                    }
                    input {
                        padding-left: 6px;
                        width: 456px;
                        height: 34px;
                        line-height: 34px;
                        outline: unset;
                        border: unset;
                        background-color: white;
                        vertical-align: middle;
                    }
                    .search-btn {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        display: flex;
                        align-items: center;
                        padding: 0 20px;
                        color: #fff;
                        cursor: pointer;
                    }
                    &.less-width-input {
                        width: 410px;

                        .search-keywords-input input {
                            width: 336px;
                        }
                    }
                }
            }
        }
        &-right {
            .recommend-new-talent-link {
                .el-button {
                    height: 20px;
                    padding: 0 8px;
                    line-height: 20px;
                    background: inherit;
                    border: 1px solid $primary;
                    border-radius: 4px;
                    font-size: 14px;
                    color: $primary;
                }
                .recommend-new-candidate-btn{
                    width: 152px;
                    height: 38px;
                    border-radius: 5px;
                    border: 1px solid #38BC9D;
                    font-size: 14px;
                    font-weight: 500;
                    color: #38BC9D;
                    line-height: 20px;
                }
            }
            .recommend-select-dropdown.el-dropdown {
                display: inline-flex;
                align-items: center;
                margin-right: 20px;
                &:nth-last-of-type(1) {
                    margin-right: 0;
                }
                .el-dropdown-link {
                    display: inline-block;
                    line-height: 26px;
                    max-width: 126px;
                    padding-right: 16px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    position: relative;
                    cursor: pointer;
                    &.actived {
                        color: $primary;
                    }
                    &:focus {
                        outline: none;
                    }
                    .el-icon-arrow-down, .el-icon-arrow-up {
                        position: absolute;
                        right: 0;
                        line-height: inherit;
                    }
                }
                &.whithout-source-select {
                    .el-dropdown-link {
                        max-width: 200px;
                    }
                }
            }
        }
    }
    .recommend-body {
        margin-top: 15px;
        margin-bottom: 10px;
        color: #444;
        .el-table {
            color: #444;
            &::before {
                height: 0;
            }
            tr:hover > td {
                background: #f8f8f8;
            }
            td {
                height: 40px;
                line-height: 40px;
                padding: 0;
                border-bottom: none;
            }
            .cell {
                padding: 0 8px;
                line-height: 1;
            }
            .name-wrapper {
                width: auto;
                max-width: 222px;
                display: inline-flex;
                align-items: center;
                .job-detail-link {
                    flex-grow: 1;
                    min-width: 20px;
                    max-width: 150px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .name-wrapper-icon {
                    width: 17px;
                    height: 17px;
                }
                &-icon {
                    margin-left: 5px;
                }
            }
            .detail-link, .recommend-btn {
                cursor: pointer;
                color: $primary;
            }
            .expected-rank, .activity-tag {
                height: 16px;
                margin-left: 6px;
                padding: 0 3px;
                border-radius: 4px;
                background: $primary;
                font-size: 12px;
                line-height: 16px;
                color: #fff;
            }
            .expected-rank {
                max-width: 68px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .activity-tag {
                background: #39f;
            }
            .location {
                max-width: 160px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .new-job-tag {
                background: #ff493c;
            }
            .el-table__empty-block {
                .el-table__empty-text {
                    line-height: inherit;
                }
                // .empty-img {
                //     display: inline-block;
                //     background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                //     width: 240px;
                //     height: 228px;
                // }
                // .empty-data {
                //     font-size: 16px;
                //     line-height: 21px;
                //     margin-bottom: 0px;
                // }

                .page-empty{
                    width: 100%;
                    padding-bottom: 30px;
                    background: white;
                    border-radius: 10px;
                    text-align: center;
                    >img{
                        width: 158px;
                        height: 178px;
                        margin: 30px auto 0;
                    }
                    .des{
                        margin: 24px auto 0;
                        font-size: 14px;
                        font-weight: 500;
                        color: #999999;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss">
    .el-dropdown-menu.recommend-body-popover{
        margin-top: 6px;
        padding: 0;
        .popper__arrow {
            display: none;
        }
        .el-dropdown-item-selected{
            background-color: rgba($primary, .1);
            color: $primary;
        }
    }
    .recommend-dialog-tooltip.el-tooltip__popper.is-light{
        margin-left: -8px;
        max-width: 300px;
        line-height: 20px;
        border: none;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

        .popper__arrow{
            border: none;
        }
    }
</style>
