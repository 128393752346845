var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "recommend-dialog-wrapper",
      attrs: {
        width: "818px",
        "show-close": true,
        "lock-scroll": false,
        visible: _vm.isShow,
        title: _vm.recommendTitle,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "recommend-header" }, [
        _c(
          "div",
          { staticClass: "recommend-header-left search-area-contianer" },
          [
            _c(
              "div",
              {
                staticClass: "search-input",
                class: { "less-width-input": _vm.authorizationFirms.length },
              },
              [
                _vm.type == 1
                  ? _c(
                      "div",
                      {
                        staticClass: "search-city-btn",
                        attrs: { title: _vm.selectedCity.text },
                      },
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom-start",
                              width: "629",
                              "visible-arrow":
                                _vm.provinceCityConfig.arrowIsShow,
                              trigger: "click",
                              "popper-class": "tooltip-province-city",
                              offset: -86,
                            },
                            model: {
                              value: _vm.provinceCityConfig.provinceCityIsShow,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.provinceCityConfig,
                                  "provinceCityIsShow",
                                  $$v
                                )
                              },
                              expression:
                                "provinceCityConfig.provinceCityIsShow",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "search-city-content",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _c("i", [
                                  _vm._v(_vm._s(_vm.selectedCity.text)),
                                ]),
                                _c("span", {
                                  staticClass: "el-icon-arrow-down",
                                }),
                              ]
                            ),
                            _c("province-city", {
                              ref: "provinceCity",
                              attrs: {
                                "grid-length": 7,
                                enableHeader:
                                  _vm.provinceCityConfig.enableHeader,
                                enableFooter:
                                  _vm.provinceCityConfig.enableFooter,
                              },
                              on: {
                                confirm: _vm.handleCitySelectConfirm,
                                cancel: _vm.handleCitySelectCancel,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "search-keywords-input",
                    class: _vm.type == 1 ? "with-city" : "",
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        slot: "reference",
                        placeholder: "输入关键字，多个关键字用空格隔开",
                        maxlength: "100",
                      },
                      nativeOn: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.getList(false, false)
                        },
                      },
                      slot: "reference",
                      model: {
                        value: _vm.searchText,
                        callback: function ($$v) {
                          _vm.searchText = $$v
                        },
                        expression: "searchText",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "search-btn",
                    on: {
                      click: function ($event) {
                        return _vm.getList(false, false)
                      },
                    },
                  },
                  [_c("span", [_vm._v("搜索")])]
                ),
              ]
            ),
          ]
        ),
        _c("div", { staticClass: "recommend-header-right" }, [
          _vm.type == 0
            ? _c(
                "div",
                [
                  _vm.authorizationFirms.length
                    ? _c(
                        "el-dropdown",
                        {
                          staticClass: "recommend-select-dropdown",
                          class: {
                            "whithout-source-select":
                              _vm.firmId && _vm.firmId !== _vm.userInfo.firmId,
                          },
                          attrs: { trigger: "click", placement: "bottom-end" },
                          on: {
                            command: _vm.firmIdSelect,
                            "visible-change": function ($event) {
                              _vm.firmIdSelectShow = !_vm.firmIdSelectShow
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "el-dropdown-link text-grey",
                              class: _vm.firmIdSelectShow ? "actived" : "",
                              attrs: { title: _vm.selectedFirmName },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.selectedFirmName) +
                                  "\n                        "
                              ),
                              _c("i", {
                                class: _vm.firmIdSelectShow
                                  ? "el-icon-arrow-up"
                                  : "el-icon-arrow-down",
                              }),
                            ]
                          ),
                          _c(
                            "el-dropdown-menu",
                            {
                              staticClass: "recommend-body-popover",
                              attrs: { slot: "dropdown" },
                              slot: "dropdown",
                            },
                            _vm._l(
                              _vm.authorizationFirms,
                              function (firm, index) {
                                return _c(
                                  "el-dropdown-item",
                                  {
                                    key: index,
                                    class:
                                      (!_vm.firmId &&
                                        firm.firmId == _vm.userInfo.firmId) ||
                                      _vm.firmId == firm.firmId
                                        ? "el-dropdown-item-selected"
                                        : "",
                                    attrs: { command: firm.firmId },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(firm.shortName) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.firmId || _vm.firmId === _vm.userInfo.firmId
                    ? _c(
                        "el-dropdown",
                        {
                          staticClass: "recommend-select-dropdown",
                          attrs: { trigger: "click", placement: "bottom-end" },
                          on: {
                            command: _vm.jobSourceSelect,
                            "visible-change": function ($event) {
                              _vm.jobSourceSelectShow = !_vm.jobSourceSelectShow
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "el-dropdown-link text-grey",
                              class: _vm.jobSourceSelectShow ? "actived" : "",
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.jobSourceSelection[_vm.jobSourceIndex]
                                      .text
                                  ) +
                                  "\n                        "
                              ),
                              _c("i", {
                                class: _vm.jobSourceSelectShow
                                  ? "el-icon-arrow-up"
                                  : "el-icon-arrow-down",
                              }),
                            ]
                          ),
                          _c(
                            "el-dropdown-menu",
                            {
                              staticClass: "recommend-body-popover",
                              attrs: { slot: "dropdown" },
                              slot: "dropdown",
                            },
                            _vm._l(
                              _vm.jobSourceSelection,
                              function (jobSource, index) {
                                return _c(
                                  "el-dropdown-item",
                                  {
                                    key: jobSource.value,
                                    class:
                                      _vm.jobSourceIndex == index
                                        ? "el-dropdown-item-selected"
                                        : "",
                                    attrs: { command: index },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(jobSource.text) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-dropdown",
                    {
                      staticClass: "recommend-select-dropdown",
                      attrs: { trigger: "click", placement: "bottom-end" },
                      on: {
                        command: _vm.orderBySelect,
                        "visible-change": function ($event) {
                          _vm.orderBySelectShow = !_vm.orderBySelectShow
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "el-dropdown-link text-grey",
                          class: _vm.orderBySelectShow ? "actived" : "",
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.orderBySelection[_vm.orderByIndex].text
                              ) +
                              "\n                        "
                          ),
                          _c("i", {
                            class: _vm.orderBySelectShow
                              ? "el-icon-arrow-up"
                              : "el-icon-arrow-down",
                          }),
                        ]
                      ),
                      _c(
                        "el-dropdown-menu",
                        {
                          staticClass: "recommend-body-popover",
                          attrs: { slot: "dropdown" },
                          slot: "dropdown",
                        },
                        _vm._l(_vm.orderBySelection, function (orderBy) {
                          return _c(
                            "el-dropdown-item",
                            {
                              key: orderBy.value,
                              class:
                                _vm.orderByIndex == orderBy.value
                                  ? "el-dropdown-item-selected"
                                  : "",
                              attrs: { command: orderBy.value },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(orderBy.text) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.type == 1
            ? _c(
                "a",
                {
                  staticClass: "recommend-new-talent-link",
                  attrs: {
                    href: "/?type=1#/resumeUpload/singleUpload",
                    target: "_blank",
                  },
                },
                [
                  _c("button", { staticClass: "recommend-new-candidate-btn" }, [
                    _vm._v("推荐新的候选人"),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "recommend-body",
        },
        [
          _vm.type == 0
            ? _c(
                "el-table",
                {
                  attrs: {
                    height: 400,
                    data: _vm.tableData,
                    "show-header": false,
                    "tooltip-effect": "light",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "职位",
                      width: "200",
                      resizable: false,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "name-wrapper" },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "light",
                                        content: scope.row.name,
                                        placement: "top",
                                        "open-delay": 300,
                                        disabled: _vm.tooltipDisabled,
                                        "popper-class":
                                          "recommend-dialog-tooltip",
                                      },
                                      nativeOn: {
                                        mouseenter: function ($event) {
                                          return _vm.handleShowTooltip($event)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "job-detail-link detail-link",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleJumpDetail(
                                                scope.row,
                                                "job"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(scope.row.name) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  scope.row.difficultDegree == 1
                                    ? _c("font-icon", {
                                        staticClass:
                                          "name-wrapper-icon tip-margin",
                                        attrs: {
                                          href: "#icon-orders_lable_hard",
                                        },
                                      })
                                    : _vm._e(),
                                  scope.row.emergencyDegree == 1
                                    ? _c("font-icon", {
                                        staticClass:
                                          "name-wrapper-icon tip-margin",
                                        attrs: {
                                          href: "#icon-orders_lable_urgent",
                                        },
                                      })
                                    : _vm._e(),
                                  scope.row.expectedRank
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "light",
                                            content: scope.row.expectedRank,
                                            placement: "top",
                                            "open-delay": 300,
                                            disabled: _vm.tooltipDisabled,
                                            "popper-class":
                                              "recommend-dialog-tooltip",
                                          },
                                          nativeOn: {
                                            mouseenter: function ($event) {
                                              return _vm.handleShowTooltip(
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "expected-rank" },
                                            [
                                              _vm._v(
                                                _vm._s(scope.row.expectedRank)
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  scope.row.activityTags.length > 0
                                    ? _vm._l(
                                        scope.row.activityTags,
                                        function (activityTag) {
                                          return _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    activityTag.id !==
                                                      "JobEmergency" &&
                                                    activityTag.id !==
                                                      "JobDifficultDegree",
                                                  expression:
                                                    "activityTag.id !== 'JobEmergency' && activityTag.id !== 'JobDifficultDegree'",
                                                },
                                              ],
                                              key: activityTag.id,
                                              staticClass: "activity-tag",
                                              class:
                                                activityTag.id == "NewJob"
                                                  ? "new-job-tag"
                                                  : "",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(activityTag.name) +
                                                  "\n                            "
                                              ),
                                            ]
                                          )
                                        }
                                      )
                                    : _vm._e(),
                                  scope.row.jobType == 3
                                    ? _c(
                                        "span",
                                        { staticClass: "activity-tag" },
                                        [
                                          _vm._v(
                                            "\n                            HR\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  scope.row.jobType == 5
                                    ? _c(
                                        "span",
                                        { staticClass: "activity-tag" },
                                        [
                                          _vm._v(
                                            "\n                            代运营\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1807073189
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "location",
                      label: "城市",
                      width: "130",
                      resizable: false,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    content: scope.row.location,
                                    placement: "top",
                                    "open-delay": 300,
                                    disabled: _vm.tooltipDisabled,
                                    "popper-class": "recommend-dialog-tooltip",
                                  },
                                  nativeOn: {
                                    mouseenter: function ($event) {
                                      return _vm.handleShowTooltip($event)
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "location" }, [
                                    _vm._v(_vm._s(scope.row.location)),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      311287194
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "customerName",
                      label: "客户",
                      width: "154",
                      resizable: false,
                      "show-overflow-tooltip": true,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "created",
                      label: "发布日期",
                      width: "140",
                      resizable: false,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(scope.row.created) +
                                  " 发布\n                "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2784183311
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "created",
                      label: "头像",
                      width: "40",
                      resizable: false,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("avatar", {
                                staticClass: "user-avatar",
                                attrs: {
                                  size: "sm",
                                  src: scope.row.avatarUrl,
                                  userId: scope.row.ownerId,
                                  enableCard: true,
                                  enableLink: false,
                                  currentJobType: scope.row.jobType,
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2519973734
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      "min-width": "28",
                      align: "center",
                      resizable: false,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "recommend-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.recommend(scope.row, "job")
                                    },
                                  },
                                },
                                [_vm._v("推荐")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1564557798
                    ),
                  }),
                  _c("template", { slot: "empty" }, [
                    _c("div", { staticClass: "page-empty" }, [
                      _c("img", {
                        attrs: {
                          src: require("@src/assets/images/jobList/empty-bg@2x.png"),
                        },
                      }),
                      _c("p", { staticClass: "des" }, [_vm._v("暂无数据")]),
                    ]),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm.type == 1
            ? _c(
                "el-table",
                {
                  attrs: {
                    height: 400,
                    data: _vm.tableData,
                    "show-header": false,
                    "tooltip-effect": "light",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "realName",
                      label: "姓名",
                      width: "62",
                      resizable: false,
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "detail-link",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleJumpDetail(
                                        scope.row,
                                        "candidate"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(scope.row.realName) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3306117548
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "location",
                      label: "城市",
                      width: "60",
                      resizable: false,
                      "show-overflow-tooltip": true,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      label: "职位",
                      width: "310",
                      resizable: false,
                      "show-overflow-tooltip": true,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "degreeInfo",
                      label: "信息",
                      width: "262",
                      resizable: false,
                      "show-overflow-tooltip": true,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      "min-width": "28",
                      resizable: false,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "recommend-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.recommend(
                                        scope.row,
                                        "candidate"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("推荐")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1183131828
                    ),
                  }),
                  _c("template", { slot: "empty" }, [
                    _c("div", { staticClass: "page-empty" }, [
                      _c("img", {
                        attrs: {
                          src: require("@src/assets/images/jobList/empty-bg@2x.png"),
                        },
                      }),
                      _c("p", { staticClass: "des" }, [_vm._v("暂无数据")]),
                    ]),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.tableData && _vm.tableData.length > 0
        ? _c("el-pagination", {
            staticClass: "pagination-common-new",
            attrs: {
              "current-page": _vm.page.current,
              "page-sizes": [10, 20, 50],
              "page-size": _vm.page.size,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.page.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }